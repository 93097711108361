const counterpartyGuard = '/auth/counterparty'
export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.browser) {
    const auth = useAuthState()
    const {
      isCounterpartyIdValid,
      isCounterpartyContractIdValid
    } = useCounterpartyCookie()
    if (auth.status.value === 'authenticated') {
      if ((!isCounterpartyIdValid.value || !isCounterpartyContractIdValid.value) && to.fullPath !== counterpartyGuard) {
        return navigateTo(counterpartyGuard)
      }
    }
  }
})
