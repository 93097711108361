export const useCounterpartyCookie = () => {
  const cookieCounterpartyName = 'counterparty_id'
  const cookieCounterpartyContractName = 'contract_id'
  const counterpartyId = useCookie<string | undefined>(cookieCounterpartyName, { default: () => undefined })
  const counterpartyContractId = useCookie<string | undefined>(cookieCounterpartyContractName, { default: () => undefined })
  const queryParameters = computed(() => {
    const parameters = {} as Record<string, string>
    if (counterpartyId.value) {
      parameters[cookieCounterpartyName] = counterpartyId.value
    }
    if (counterpartyContractId.value) {
      parameters[cookieCounterpartyContractName] = counterpartyContractId.value
    }
    return parameters
  })
  const isCounterpartyIdValid = computed(() => typeof counterpartyId.value === 'string' && counterpartyId.value.length > 0)
  const isCounterpartyContractIdValid = computed(() => typeof counterpartyContractId.value === 'string' && counterpartyContractId.value.length > 0)
  const reset = () => {
    counterpartyId.value = undefined
    counterpartyContractId.value = undefined
  }
  return {
    reset,
    counterpartyId,
    counterpartyContractId,
    contractId: counterpartyContractId,
    isCounterpartyIdValid,
    isCounterpartyContractIdValid,
    queryParameters
  }
}
