
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexKGFpJrfaPGuuhppiHh6jzgX1cc0MaKSlIxKJ_45q3hxrYMeta } from "/app/pages/account/index.vue?macro=true";
import { default as counterparty5gv2r98g6lNgex2uCvs54hZkIsZv3Zg1Bfzgf4idm6oMeta } from "/app/pages/auth/counterparty.vue?macro=true";
import { default as index0pIUpb4VcnCWnMBi6_45JL7id3qfXhxyhp21l7BuSVOmMMeta } from "/app/pages/auth/login/index.vue?macro=true";
import { default as phonen8qEHkyydbRsJ76j1KvOrf3vt2sRNPkEpK9ERlrvY0AMeta } from "/app/pages/auth/login/phone.vue?macro=true";
import { default as recoveryITr4WD9limFfCUnJOcJNXSUwaRKRzVI9HyM7vCLE7qkMeta } from "/app/pages/auth/recovery.vue?macro=true";
import { default as indexgXAvaMPwKrurPyfrnchlOoI4Og1SV7Q_45uxSxTgQKpqEMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as _91id_93iX5VV4f6GD6AcdhmvTqhN4ce37Rvo8HCR0whlGBzeF0Meta } from "/app/pages/category/[id].vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as indexDCqhPzutOZTeEBErhbpXyxz9LSZCrOqFoLSpaIOOmIIMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as _91id_93v15slvlvNAxNNuHchmMPm0LvNxqcQdVMh7sJho0kZtIMeta } from "/app/pages/product/[id].vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: indexKGFpJrfaPGuuhppiHh6jzgX1cc0MaKSlIxKJ_45q3hxrYMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "auth-counterparty",
    path: "/auth/counterparty",
    meta: counterparty5gv2r98g6lNgex2uCvs54hZkIsZv3Zg1Bfzgf4idm6oMeta || {},
    component: () => import("/app/pages/auth/counterparty.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: index0pIUpb4VcnCWnMBi6_45JL7id3qfXhxyhp21l7BuSVOmMMeta || {},
    component: () => import("/app/pages/auth/login/index.vue")
  },
  {
    name: "auth-login-phone",
    path: "/auth/login/phone",
    meta: phonen8qEHkyydbRsJ76j1KvOrf3vt2sRNPkEpK9ERlrvY0AMeta || {},
    component: () => import("/app/pages/auth/login/phone.vue")
  },
  {
    name: "auth-recovery",
    path: "/auth/recovery",
    meta: recoveryITr4WD9limFfCUnJOcJNXSUwaRKRzVI9HyM7vCLE7qkMeta || {},
    component: () => import("/app/pages/auth/recovery.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexgXAvaMPwKrurPyfrnchlOoI4Og1SV7Q_45uxSxTgQKpqEMeta || {},
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "category-id",
    path: "/category/:id()",
    meta: _91id_93iX5VV4f6GD6AcdhmvTqhN4ce37Rvo8HCR0whlGBzeF0Meta || {},
    component: () => import("/app/pages/category/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexDCqhPzutOZTeEBErhbpXyxz9LSZCrOqFoLSpaIOOmIIMeta || {},
    component: () => import("/app/pages/orders/index.vue")
  },
  {
    name: "product-id",
    path: "/product/:id()",
    meta: _91id_93v15slvlvNAxNNuHchmMPm0LvNxqcQdVMh7sJho0kZtIMeta || {},
    component: () => import("/app/pages/product/[id].vue")
  }
]